import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "true-heart-solved",
      "style": {
        "position": "relative"
      }
    }}>{`True Heart (Solved)`}<a parentName="h1" {...{
        "href": "#true-heart-solved",
        "aria-label": "true heart solved permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p><em parentName="p">{`We have remembered,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`that is all there is to say.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`We have `}<Link to="https://thebluebook.co.za/four-part-harmony/" mdxType="Link">{`remembered`}</Link>{`.`}</em>{`  `}</p>
    <p>{`What is Kernel, after all? Of course, there are the educational tracks it combines, the skills it teaches, the people it connects, the relationships it fosters at many different levels. All of these are really the outer form, though. What is the inner meaning of these eight weeks in which we gather?`}</p>
    <p>{`Sometimes, the world can seem dark. Climate change; the lack of real leadership; the will to power, profit, extract and control; global pandemic; violence and the abuse of women and children; racism. Everywhere we look, separation seems to dominate. If you experience the magic of Kernel, it can even be frustrating: "What effect can this little ray of light have in such a world?"`}</p>
    <p>{`Yet, it is also the only thing that really matters. It proves that, despite the overwhelming shadows, nothing can conquer light. You just have to remember this simple fact. It doesn't matter where you are, what you do, what name it has, what logo it uses, or what the excuse for gathering is: the light is ever-present, because it is your own nature. It's just that we forget, close our eyes, and then complain of the darkness all around. Kernel is a sacred invitation to see again, though not in any particular way. It is a digital translation of the first human greeting there was, which we met back in `}<a parentName="p" {...{
        "href": "/learn/module-0/conversation"
      }}>{`Module 0`}</a>{` with the !Gwi people: `}<em parentName="p">{`tsamkwa /tge?`}</em></p>
    <p>{`Are your eyes nicely open?`}</p>
    <hr></hr>
    <p>{`The koan for this last week begins `}<Link to="https://libraryofbabel.info/bookmark.cgi?trueheart" mdxType="Link">{`here`}</Link>{`. The NFT prize is generated from the style "Infinite Beauty" on ethblock.art and is called `}<Link to="https://ethblock.art/view/266" mdxType="Link">{`HeartMap`}</Link>{`. The naming of the sidebar here has changed with the new website, so you should know that there are koans for Modules 1, 3 and 7 if you're going to attempt to solve this. Good luck!`}</p>
    <p>{`This week, we travel with `}<Link to="https://www.brainpickings.org/2019/10/23/the-weighing-jane-hirshfield/" mdxType="Link">{`Jane Hirschfield`}</Link>{`:`}</p>
    <p><em parentName="p">{`So few grains of happiness`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`measured against all the dark`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`and still the scales balance.`}</em>{`  `}</p>
    <Aligner center mdxType="Aligner">
      <Image style={{
        "width": "60%"
      }} alt="Heart Map" src="/images/koans/heartmap.png" mdxType="Image" />
    </Aligner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      